export function calculateCompoundInterest(principal, frequency, years, rate) {
    const frequencies = {
      'dagligen': 365,
      'varannan dag': 365 / 2,
      'per månad': 12,
      'per år': 1,
      'en gång (idag)': 0
    };
  
    const n = frequencies[frequency];
    const t = years;
    const r = rate / 100;
  
    let amount = 0;
    if (n === 0) {
      amount = principal * Math.pow((1 + r), t);
    } else {
      for (let i = 0; i < n * t; i++) {
        amount += principal;
        amount *= (1 + r / n);
      }
    }
    return amount.toFixed(2);
  }
  