import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { calculateCompoundInterest } from '../utils/compoundCalculator';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Compounder() {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('SEK');
  const [frequency, setFrequency] = useState('dagligen');
  const [rate, setRate] = useState(7);
  const [years, setYears] = useState(25);
  const [result, setResult] = useState(null);
  const [chartData, setChartData] = useState(null);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const handleCalculate = () => {
    const principal = parseFloat(amount);
    if (isNaN(principal) || principal <= 0) {
      alert('Ange ett giltigt belopp.');
      return;
    }

    const finalAmount = calculateCompoundInterest(principal, frequency, years, rate);
    setResult(finalAmount);

    const data = [];
    for (let year = 1; year <= years; year++) {
      const yearAmount = calculateCompoundInterest(principal, frequency, year, rate);
      data.push(yearAmount);
    }

    setChartData({
      labels: Array.from({ length: years }, (_, i) => i + 1),
      datasets: [
        {
          label: 'Utveckling över tid',
          data: data,
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.1
        }
      ]
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">
        Har du koll på <strong>"ränta-på-ränta effekten"</strong> och hur den påverkar din privatekonomi?
        Visste du att genom att bara spara in på en dosa <strong>"vitt snus"</strong> om dagen kan du spara
        till över <strong>"en miljon"</strong> på ca 25 år? Prova vår ränta-på-ränta beräknare nedan för
        att se vad just du kan spara i vardagen.
      </h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
          Belopp
        </label>
        <div className="flex">
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2"
          >
            <option value="SEK">SEK</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
          </select>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="frequency">
          Hur ofta
        </label>
        <select
          id="frequency"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="dagligen">dagligen</option>
          <option value="varannan dag">varannan dag</option>
          <option value="per månad">per månad</option>
          <option value="per år">per år</option>
          <option value="en gång (idag)">en gång (idag)</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
          Årlig avkastning (%)
        </label>
        <input
          type="number"
          id="rate"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="years">
          Tidsperiod (år)
        </label>
        <input
          type="number"
          id="years"
          value={years}
          onChange={(e) => setYears(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
        onClick={handleCalculate}
      >
        Beräkna!
      </button>
      {result && (
        <div className="mt-4">
          <h3 className="text-xl">Med en årlig avkastning på <strong>{rate} %</strong> skulle din besparing vara värd <strong>{formatNumber(result)}</strong> {currency}.</h3>
        </div>
      )}
      {chartData && (
        <div className="mt-4">
          <Line data={chartData} options={{
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'År'
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Belopp'
                }
              }
            },
            plugins: {
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  pointStyle: 'line'
                }
              }
            }
          }} />
        </div>
      )}
      <p className="text-xs mt-4">
        Notera att det alltid är en risk att investera pengar och man kan förlora delar, eller hela beloppet.
      </p>
    </div>
  );
}

export default Compounder;
